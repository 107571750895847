import Vue from 'vue'
import App from './components/Widget.vue'

// plugins

// import './assets/css/main.scss'

// widget setup
// (optional) 'Custom elements polyfill'
import 'document-register-element/build/document-register-element'
// vue-custom-element by karol-f
import vueCustomElement from 'vue-custom-element'

// Axios
import axios from 'axios'
Vue.use(vueCustomElement)
Vue.prototype.$axios = axios

Vue.config.productionTip = false

Vue.customElement('mag-widget', App)
