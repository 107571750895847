<template>
  <div id="MAG_Distribution_Widget" ref="widget" class="widget-container">
    <div class="MAG-widget" v-if="widgetId && receivedData">

      <!--Tabs-->
      <div class="MAG-tabs" :class="{ 'MAG-theme-pill': theme.tabs.style === 'pill', 'tabs': theme.tabs.style != 'pill' }">
        <div class="MAG-tab"
             v-if="Object.keys(tabs.options).length > 1"
             v-for="tab in tabs.options"
             :class="{ 'MAG-active': tab === currentTab }"
             :key="tab.position"
             @click="selectTab(tab)">
          {{ tab.label }}
        </div>
      </div>

      <!--Parking-->
      <div class="MAG-tab-content-container" :class="{ 'no-tabs': Object.keys(tabs.options).length <= 1 || theme.tabs.style === 'pill' }" v-if="currentTab">
      <div class="MAG-tab-content parking" v-show="currentTab.label === 'Parking'">

        <!-- Entry Date & Time -->
        <div class="MAG-tab-input-group" :class="{ 'MAG-theme-pill': theme.tabs.style === 'MAG-theme-pill', 'tabs': theme.tabs.style != 'pill' }">

          <!-- Entry Date -->
          <div class="MAG-tab-input"
               tabindex="0"
               @click="errors.entryDate = null; errors.bottom = null"
               @focus="onDatePickerFocus('entryDatepicker')"
               @blur="onDatePickerBlur('entryDatepicker')">
            <span class="MAG-heading">{{ theme.labels.entry }} Date</span>
            <datepicker
                ref="entryDatepicker"
                :placeholder="theme.inputs.dateInput.placeholder ? theme.inputs.dateInput.placeholder : 'Select Date'"
                :disabled-dates="entryDateRange" :open-date="openDate"
                :format="theme.inputs.dateInput.format"
                :class="{ 'MAG-show-icon': theme.inputs.showIcons, 'MAG-white-icon': theme.inputs.isWhiteText }"
                v-model="entryDate"></datepicker>
            <div class="MAG-error-container" v-if="errors.entryDate"><div class="MAG-error">{{ errors.entryDate }}</div></div>
          </div>

          <!-- Entry Time -->
          <div class="MAG-tab-input" tabindex="0" @focus="showEntryTime = true" @blur="entryTimeBlur()" @click="errors.entryTime = null; errors.bottom = null">
            <div class="MAG-heading">{{ theme.labels.entry }} Time</div>
            <div class="MAG-time-dropdown-container">
              <div class="MAG-time-dropdown-value"
                   :class="{
                           'MAG-text-white': theme.inputs.isWhiteText,
                           'MAG-show-icon': theme.inputs.showIcons,
                           }">
                <div class="MAG-w-full">{{ entryTime ? times[entryTime] : theme.inputs.timeInput.placeholder }}</div>
              </div>
              <ul class="MAG-time-dropdown" :class="{'MAG-isWhite': theme.inputs.isWhiteText}" v-show="showEntryTime">
                <li v-for="(time, key) in times"
                    :key="key"
                    :class="{ 'MAG-active': entryTime === key }"
                    @click="entryTimeSelected(key)">
                  {{ time }}
                </li>
              </ul>
            </div>

            <div class="MAG-error-container" v-if="errors.entryTime"><div class="MAG-error">{{ errors.entryTime }}</div></div>
          </div>
        </div>

        <!-- Exit Date & Time -->
        <div class="MAG-tab-input-group">

          <!-- Exit Date -->
          <div class="MAG-tab-input"
               tabindex="0"
               @click="errors.exitDate = null; errors.bottom = null"
               @focus="onDatePickerFocus('exitDatepicker')"
               @blur="onDatePickerBlur('exitDatepicker')">
            <span class="MAG-heading">{{ theme.labels.exit }} Date</span>
            <datepicker
                ref="exitDatepicker"
                :placeholder="theme.inputs.dateInput.placeholder ? theme.inputs.dateInput.placeholder : 'Select Date'"
                :disabled-dates="exitDateRange" :open-date="openDate"
                :format="theme.inputs.dateInput.format"
                :class="{ 'MAG-show-icon': theme.inputs.showIcons, 'MAG-white-icon': theme.inputs.isWhiteText }"
                v-model="exitDate"></datepicker>
            <div class="MAG-error-container" v-if="errors.exitDate"><div class="MAG-error">{{ errors.exitDate }}</div></div>
          </div>

          <!-- Exit Time -->
          <div class="MAG-tab-input" tabindex="0" @focus="showExitTime = true" @blur="exitTimeBlur()"  @click="errors.exitTime = null; errors.bottom = null">
            <div class="MAG-heading">{{ theme.labels.exit }} Time</div>
            <div class="MAG-time-dropdown-container">
              <div class="MAG-time-dropdown-value"
                   :class="{
                            'MAG-text-white': theme.inputs.isWhiteText,
                            'MAG-show-icon': theme.inputs.showIcons,
                            }">
                <div class="MAG-w-full">{{ exitTime ? times[exitTime] : theme.inputs.timeInput.placeholder }}</div>
              </div>
              <ul class="MAG-time-dropdown"
                  :class="{'MAG-isWhite': theme.inputs.isWhiteText}" v-show="showExitTime">
                <li v-for="(time, key) in times"
                    :key="key"
                    :class="{ 'MAG-active': exitTime === key }"
                    @click="exitTimeSelected(key)">
                  {{ time }}
                </li>
              </ul>
            </div>

            <div class="MAG-error-container" v-if="errors.exitTime"><div class="MAG-error">{{ errors.exitTime }}</div></div>
          </div>
        </div>

        <!-- Submit -->
        <div class="MAG-tab-submit">
          <div class="MAG-heading MAG-invisible" v-if="theme.inputs.bgColor != 'transparent'" v-show="$refs.widget.clientWidth > 965">Submit</div>
          <button class="MAG-submit" @click.prevent="submitParking()" :disabled="formSubmitted">
            <div class="MAG-loader" v-show="formSubmitted"></div>
            <div v-show="!formSubmitted">{{ theme.submitButton.label }}</div>
          </button>
        </div>
      </div>
        <div class="MAG-error-container MAG-no-after" v-if="errors.bottom"><div class="MAG-error">{{ errors.bottom }}</div></div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment/src/moment'

import '../assets/css/main.scss'

export default {
  name: 'widget',
  components: {
    Datepicker
  },
  props: {
    widgetId: {
      type: String,
      required: true
    },
    custom: {
      type: Object,
      required: false
    }
  },

  data () {
    return {
      currentTab: '',
      receivedData: false,
      formSubmitted: false,
      tabs: {},
      theme: {},
      openDate: new Date(),
      dates: {
        entryDate: '',
        entryTime: '',
        exitDate: '',
        exitTime: ''
      },
      errors: {
        entryDate: null,
        entryTime: null,
        exitDate: null,
        exitTime: null,
        bottom: null
      },
      times: this.getTimeOptions(),
      showEntryTime: false,
      showExitTime: false
    }
  },

  watch: {
    defaultTab: {
      handler: function (val, oldVal) {
        this.currentTab = val
      },
      deep: true
    },
    custom: {
      handler: function (val, oldVal) {
        this.theme = val.theme
        this.tabs = val.tabs
        this.updateElements()
        console.log('updated theme/tabs')
      },
      deep: true
    }
  },

  mounted () {
    this.currentTab = this.defaultTab
    this.getData()
  },

  computed: {
    entryDateRange () {
      const yesterday = moment().subtract(1, 'days')
      const nextYear = moment().add(1, 'years')
      return { to: new Date(yesterday), from: new Date(nextYear) }
    },
    exitDateRange () {
      const yesterday = moment().subtract(1, 'days')
      const nextYear = moment().add(1, 'years')
      let to = new Date(this.dates.entryDate ? this.dates.entryDate : yesterday)
      return { to: new Date(to), from: new Date(nextYear) }
    },
    entryDate: {
      get () {
        return this.dates.entryDate
      },
      set (value) {
        this.errors.entryDate = null
        this.openDate = value
        this.dates.entryDate = value
        this.dates.exitDate = ''
        this.dates.exitTime = ''
      }
    },
    entryTime: {
      get () {
        return this.dates.entryTime
      },
      set (value) {
        this.errors.entryTime = null
        this.dates.entryTime = value
      }
    },
    exitDate: {
      get () {
        return this.dates.exitDate
      },
      set (value) {
        this.errors.exitDate = null
        this.dates.exitDate = value
      }
    },
    exitTime: {
      get () {
        return this.dates.exitTime
      },
      set (value) {
        this.errors.exitTime = null
        this.dates.exitTime = value
      }
    }
  },

  methods: {
    selectTab (tab) {
      if (tab !== this.currentTab) {
        this.$emit('TabChanged', `${this.currentTab} to ${tab}`)
        this.currentTab = tab
      }
    },

    entryTimeBlur () {
      setTimeout(() => {
        this.showEntryTime = false
        document.activeElement.blur()
      }, 300)
    },

    exitTimeBlur () {
      setTimeout(() => {
        this.showExitTime = false
        document.activeElement.blur()
      }, 300)
    },

    entryTimeSelected (key) {
      this.entryTime = key
      document.activeElement.blur()
    },

    exitTimeSelected (key) {
      this.exitTime = key
      document.activeElement.blur()
    },

    updateExitDate () {
      let entryDate = new Date(this.entryDate).toJSON().slice(0, 10)
      let exitDate = new Date(this.exitDate).toJSON().slice(0, 10)
      if (entryDate < exitDate) {
        this.exitDate = ''
        this.exitTime = ''
      }
    },

    getTimeOptions () {
      let startTime = moment().utc().set({ day: 1, hour: 24, minute: 0 })
      let endTime = moment().utc().set({ day: 2, hour: 23, minute: 59 })
      let timeStops = {}

      while (startTime <= endTime) {
        let time = moment(startTime)
        timeStops[time.format('HH:mm')] = time.format('h:mm A')
        startTime = startTime.add(15, 'minutes')
      }

      return timeStops
    },

    onDatePickerFocus (datepicker) {
      setTimeout((ev) => {
        this.$refs[datepicker].isOpen || this.$refs[datepicker].showCalendar(ev)
      }, 50)
    },

    onDatePickerBlur (datepicker) {
      setTimeout((ev) => {
        !this.$refs[datepicker].isOpen || this.$refs[datepicker].showCalendar(false)
      }, 50)
    },

    getData () {
      this.$axios.get(`https://d3hl1nehel6h56.cloudfront.net/applications/${this.widgetId}/widget.json`)
        .then((response) => {
          if (response.data.data.tabs.default) {
            this.currentTab = response.data.data.tabs.options[response.data.data.tabs.default]
          }
          this.receivedData = true
          this.tabs = response.data.data.tabs
          this.theme = response.data.theme
          this.createStyle(this.styles(response.data.theme))
        })
        .then(() => {
          this.updateElements()
        })
    },

    updateElements () {
      let DatePickerRefs = ['entryDatepicker', 'exitDatepicker']

      DatePickerRefs.forEach((ref) => {
        if (this.$refs[ref]) {
          this.$refs[ref].$el.querySelector('input[type="text"]').style.borderRadius = this.theme.inputs.borderRadius
          this.$refs[ref].$el.querySelector('input[type="text"]').style.backgroundColor = this.theme.inputs.bgColor
          this.$refs[ref].$el.querySelector('input[type="text"]').style.color = this.theme.inputs.fontColor
          this.$refs[ref].$el.querySelector('input[type="text"]').style.fontWeight = this.theme.inputs.fontWeight
          this.$refs[ref].$el.querySelector('input[type="text"]').style.padding = this.theme.inputs.padding
        } else {
          console.log('Cannot find ' + ref)
        }
      })
    },

    createStyle (styles) {
      /* Create style element */
      var css = document.createElement('style')
      css.type = 'text/css'

      if (css.styleSheet) {
        css.styleSheet.cssText = styles
      } else {
        css.appendChild(document.createTextNode(styles))
      }

      /* Append style to the head element */
      document.getElementsByTagName('head')[0].appendChild(css)

      /* Add Polyfill for IE11 */
      window.MSInputMethodContext && document.documentMode && this.ieCSSPolyfill()
    },

    styles (theme) {
      /* Declare the style element */
      var styles = ':root {\n'

      for (const [key, value] of Object.entries(theme)) {
        for (const [childKey, childValue] of Object.entries(value)) {
          if (typeof childValue === 'object') {
            for (const [childChildKey, childChildValue] of Object.entries(childValue)) {
              styles += `--${key}-${childKey}-${childChildKey}: ${childChildValue};\n`
            }
          } else {
            styles += `--${key}-${childKey}: ${childValue};\n`
          }
        }
      }

      styles += '}'

      return styles
    },

    ieCSSPolyfill () {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://cdn.jsdelivr.net/gh/nuxodin/ie11CustomProperties@4.1.0/ie11CustomProperties.min.js'

      document.getElementsByTagName('head')[0].appendChild(script)
    },

    validation () {
      const entryDate = moment(this.dates.entryDate).format('YYYY-MM-DD')
      const entryDateTime = moment(entryDate + ' ' + this.dates.entryTime, 'YYYY-MM-DD HH:mm')
      const exitDate = moment(this.dates.exitDate).format('YYYY-MM-DD')
      const exitDateTime = moment(exitDate + ' ' + this.dates.exitTime, 'YYYY-MM-DD HH:mm')

      let error = false
      if (!this.dates.entryDate) {
        this.errors.entryDate = 'Please Select Date'
        error = true
      }
      if (!this.dates.entryTime) {
        this.errors.entryTime = 'Please Select Time'
        error = true
      }
      if (!this.dates.exitDate) {
        this.errors.exitDate = 'Please Select Date'
        error = true
      }
      if (!this.dates.exitTime) {
        this.errors.exitTime = 'Please Select Time'
        error = true
      }
      if (error === false && entryDateTime.diff(exitDateTime, 'minutes') >= 0) {
        this.errors.bottom = 'Car parking cannot be booked before or on the ' + this.theme.labels.entry + ' time'
        error = true
      }
      if (error === false && moment().diff(entryDateTime, 'minutes') > -60) {
        this.errors.bottom = 'Car parking cannot be pre booked less than 1 hour in advance'
        error = true
      }
      return error
    },

    submitParking () {
      // Do something
      if (!this.formSubmitted) {
        if (this.validation()) return false

        this.formSubmitted = true

        if (this.currentTab.redirectUrl) {
          let redirectUrl = this.currentTab.redirectUrl

          for (const [key, value] of Object.entries(this.currentTab.variables)) {
            if (key === 'entryDate' || key === 'exitDate') {
              redirectUrl = redirectUrl.replace(value.pattern, moment(this.dates[key]).format(value.format))
            } else if (key === 'entryTime' || 'exitTime') {
              redirectUrl = redirectUrl.replace(value.pattern, moment(this.dates[key], 'HH:mm').format(value.format))
            }
          }

          window.location = redirectUrl
        }

        setTimeout(() => {
          this.formSubmitted = false
        }, 5000)
      }
    }
  }
}
</script>
